import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import style from "./components/style.css";

import { Route, BrowserRouter, Routes } from "react-router-dom";

import {
  Login,
  Cadastro,
  Redefinir,
  Home,
  Form1,
  Form2,
  Form3,
  Aceite,
  Contato,
  Contrato,
  Financeiro,
  Recibo,
  Inicio,
  Tipo,
  Prorrogacao,
} from "./pages";

export const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/cadastro" element={<Cadastro />} />
        <Route path="/redefinir" element={<Redefinir />} />
        <Route path="/home" element={<Home />} />
        <Route path="/form1" element={<Form1 />} />
        <Route path="/form2" element={<Form2 />} />
        <Route path="/form3" element={<Form3 />} />
        <Route path="/aceite" element={<Aceite />} />
        <Route path="/contato" element={<Contato />} />
        <Route path="/contrato" element={<Contrato />} />
        <Route path="/financeiro" element={<Financeiro />} />
        <Route path="/recibo" element={<Recibo />} />
        <Route path="/inicio" element={<Inicio />} />
        <Route path="/tipo" element={<Tipo />} />
        <Route path="/prorrogacao" element={<Prorrogacao />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
