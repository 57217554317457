import { useState, useEffect } from "react";
import { Frame, Loading, Header } from "../components";
import { db, auth } from "../config/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const Tipo = () => {
  let uid = auth.currentUser?.uid;

  const [dados, setDados] = useState({});
  const [tipo, setTipo] = useState("1");
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const setData = async () => {
    await setDoc(doc(db, "cangucu2024", uid), { tipo: tipo }, { merge: true });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    setData().then(() => navigate("../aceite"));
  };

  const getData = async () => {
    const qry = await getDoc(doc(db, "cangucu2024", uid));

    const data = qry.data();

    if (data?.status) {
      navigate("../home");
    } else {
      setDados(data);
      setLoading(false);
    }
  };

  const checkLogin = async () => {
    if (uid) getData();
    else
      onAuthStateChanged(auth, (user) => {
        if (user?.uid) {
          uid = user.uid;
          getData();
        } else navigate("../login");
      });
  };

  useEffect(() => {
    checkLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header nome={dados?.nome} foto={dados?.urlFoto} />

      {loading && <Loading />}

      <Frame
        body={
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            className="m-3 text-start"
            noValidate
          >
            <div className="my-3">
              <div className="form-check my-4">
                <input
                  className="form-check-input"
                  type="radio"
                  name="tipo"
                  id="tipo1"
                  onClick={() => setTipo("1")}
                  defaultChecked={tipo === "1"}
                />
                <label className="form-check-label" htmlFor="tipo1">
                  <b>MENSAL:</b> 44 créditos - R$ 585,00.
                </label>
              </div>
              <div className="form-check my-4">
                <input
                  className="form-check-input"
                  type="radio"
                  name="tipo"
                  id="tipo2"
                  onClick={() => setTipo("2")}
                  defaultChecked={tipo === "2"}
                />
                <label className="form-check-label" htmlFor="tipo2">
                  <b>3 VEZES POR SEMANA:</b> 24 créditos - R$ 420,00.
                </label>
              </div>
            </div>
            <div className="d-grid">
              <button type="submit" className="btn btn-primary">
                ENVIAR
              </button>
            </div>
          </form>
        }
        title="Tipo de Contrato"
      />
    </>
  );
};

export default Tipo;
