import Logo from "../assets/logo2.png";
const Frame = (props) => {
  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-center text-center">
        <div className="col col-sm-8">
          {props.logo && (
            <div className="mt-4">
              <img src={Logo} alt="Logo Embaixador" width={275} />
              <h5 className="mt-2 fw-bolder azul-logo">FRETAMENTO CANGUÇU 2024</h5>
            </div>
          )}
          <div className="p-4 shadow bg-white rounded my-4">
            {props.title && (
              <h5>
                {props.title} <hr />
              </h5>
            )}
            <div>{props.body}</div>
            <div>{props.footer}</div>
          </div>
          {props.copyright && (
            <div className="small">
              Copyright &copy; 1960-2024 &mdash; Expresso Embaixador LTDA.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Frame;
